*{
  margin:0;
  padding:0;
  outline:0;
  box-sizing:border-box;
}
body {
  font-family: Segoe UI, Arial, Helvetica, sans-serif;
  font-size:1rem;
  line-height: 1.75rem;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  background-image: url('https://res.cloudinary.com/daty3sgrj/image/upload/v1566849313/Background/bgcolor_b8ijbu.jpg')
}
html , body , #root {
  height:100%;
}
hr{
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}


.slick-prev, .slick-next , .slick-arrow{
  display: none !important;
}

.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}

.ColorMDPrimary{
  color:#3346ED;
}


/*Animaçoes*/

@keyframes moveLeft{
  0%{
      opacity: 0;
      transform: translateX(-10rem);
  }
  100%{
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveRight{
  0%{
      opacity: 0;
      transform: translateX(10rem);
  }
  100%{
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveUp{
  0%{
      opacity: 0;
      transform: translateY(2rem);
  }
  100%{
      opacity: 1;
      transform: translate(0);
  }
}


