.avatar-faleconosco{
    position: fixed;
    bottom:0;
    right: 0;
    width:120px;
    height:125px;
    margin: 2rem 1rem;
    opacity: 0.9;
    z-index: 10;
}

.avatar-faleconosco:hover{
  opacity: 1;
}


.avatar-text::after{
    content: 'Posso Ajudar?';
    position: fixed;
    opacity: 0.9;
    bottom: 15px;
    right:100px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.7rem;
    background: #fafafa;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px 1px #bbb;  
    border-radius: 2rem;
    margin: 2.5rem 1rem;
    padding: .4rem .8rem;
    z-index: 10;
}

.avatar-text:hover{
    opacity: 1;
}