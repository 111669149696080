.AnyClassForContainer {
    position: fixed;
    left: -100px;
    bottom: 80px;
    transition: left 0.5s;
    cursor: pointer;
    background-color: #3346ED;
    padding: 10px;
    opacity: 0.6;
    z-index: 10;
  }
  
  .AnyClassForTransition {
    left: 20px;
  }
  .AnyClassForContainer:hover{
      opacity: 1;
  }